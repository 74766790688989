(function ($) {
    var TableCarousel = function ($self, options) {
        this.$self = $self;
        this.options = $.extend({}, $.fn.tableCarousel.defaults, options);
        this.data = {};
        this.data.position = 0;
    };
	var swipe = 0;
    //Display Plugin
    TableCarousel.prototype.display = function () {
    	this.data.maxColumns = this.options.columns;
        this.data.numColumns = Math.ceil($(this.$self).find("ul.table-carousel-header").find('li').length);
        var parentWidth = $(this.$self).parent().width();
        this.data.columnWidth = Math.floor(parentWidth / this.options.columns);
        $(this.$self).find('li').css('width', this.data.columnWidth + 'px');
        $(this.$self).find('ul li').first().prop('class', 'active');
    };

    //Initialize Plugin
    TableCarousel.prototype.init = function () {
        /*$(this.$self).wrapAll('<div class="tbl-caro-contents" />');*/

        $(this.options.leftBtn).on('click', function () {
            this.left();
        }.bind(this));

        $(this.options.rightBtn).on('click', function () {
            this.right();
        }.bind(this));

        //display carousel
        this.display();
    };

    //Update plugin parameters
    TableCarousel.prototype.update = function (args) {
        this.options = $.extend({}, $.fn.tableCarousel.defaults, args);
        $(this.$self).find('ul').css('left', '0px');
        this.display();
        //stuff
    };

    //Resize component
    TableCarousel.prototype.resize = function () {
        var parentWidth = $(this.$self).parent().width();
        swipe = 0;
        this.data.position = 0;
        this.data.columnWidth = Math.floor(parentWidth / this.options.columns);
        $(this.$self).find('li').css('width', this.data.columnWidth + 'px');
        var activeItem = $(this.$self).find('ul').first().find('li.active');
        var imgleftPosition = -parseInt($(activeItem).position().left);
        var leftPosition = -parseInt($(activeItem).position().left);
        $(this.$self).find('ul.table-carousel-header').animate({ 'left': imgleftPosition + 'px' }, 150);
        $(this.$self).find('ul.table-carousel-row').animate({ 'left': leftPosition + 'px' }, 150);
        $('.left-scroller').css('pointer-events', 'none');
        $('.left-scroller .fa-caret-circle-left').css('color', '#808080');
        $('.right-scroller').css('pointer-events', ''); // Change for Disable functionality
        $('.right-scroller .fa-caret-circle-right').css('color', ''); // Change for Disable functionality
    };

    //Scroll carousel left
    TableCarousel.prototype.left = function () {
    	$('.left-scroller').css('pointer-events', ''); // Change for Disable functionality
        $('.left-scroller .fa-caret-circle-left').css('color', ''); // Change for Disable functionality
    	if ((this.data.numColumns > this.options.columns) && !$(this.$self).find('ul').is(':animated')) {
            var activeItem = $(this.$self).find('ul').first().find('li.active');
            var wrapping = false;
            var dontmove = false;
            if ((this.data.position + this.options.columns) >= this.data.numColumns) {
                dontmove = true;
            } else {
                //continue
                this.data.position++;
            }
            if (!dontmove) {
                var imgleftPosition = -parseInt($(activeItem).next().position().left);
                if (window.innerWidth < 1000){
                swipe++;
                var leftPosition = -parseInt($(activeItem).next().position().left)+(4*swipe);
                }
                else {
                var leftPosition = -parseInt($(activeItem).next().position().left);
                }
                $(activeItem).next().toggleClass('active');
                $(activeItem).toggleClass('active');
                $(this.$self).find('ul.table-carousel-header').animate({ 'left': imgleftPosition + 'px' }, 150);
                $(this.$self).find('ul.table-carousel-row').animate({ 'left': leftPosition + 'px' }, 150);
            }
            // Change for Disable functionality-start
            if ((this.data.position + this.options.columns + 1) > this.data.numColumns) {
                $('.right-scroller').css('pointer-events', 'none');
                $('.right-scroller .fa-caret-circle-right').css('color', '#808080');
            }
            else {
            	$('.right-scroller').css('pointer-events', '');
                $('.right-scroller .fa-caret-circle-right').css('color', '');            	
            }
            // Change for Disable functionality-end
        }
    };

    //Scroll carousel right
    TableCarousel.prototype.right = function () {
    	$('.right-scroller').css('pointer-events', ''); // Change for Disable functionality
        $('.right-scroller .fa-caret-circle-right').css('color', '');  // Change for Disable functionality
    	if ((this.data.numColumns > this.options.columns) && !$(this.$self).find('ul').is(':animated')) {
            var activeItem = $(this.$self).find('ul').first().find('li.active');
            var wrapping = false;
            var dontmove = false;
            if (this.data.position-1 < 0) {
                dontmove = true;
            } else {
                //continue
                this.data.position--;
            }
            if (!dontmove) {
            	var imgleftPosition = -parseInt($(activeItem).prev().position().left);
                if (window.innerWidth < 1000){
                swipe--;
                var leftPosition = -parseInt($(activeItem).prev().position().left)+(4*swipe);
                }
                else {
                var leftPosition = -parseInt($(activeItem).prev().position().left);
                }
                $(activeItem).prev().toggleClass('active');
                $(activeItem).toggleClass('active');
                $(this.$self).find('ul.table-carousel-header').animate({ 'left': imgleftPosition + 'px' }, 150);
                $(this.$self).find('ul.table-carousel-row').animate({ 'left': leftPosition + 'px' }, 150);
            }
            // Change for Disable functionality-start
            if (this.data.position-1 < 0) {
                $('.left-scroller').css('pointer-events', 'none');
                $('.left-scroller .fa-caret-circle-left').css('color', '#808080');
            }
            else {
            	$('.left-scroller').css('pointer-events', '');
                $('.left-scroller .fa-caret-circle-left').css('color', '');            	
            }
            // Change for Disable functionality-end
        }
    };


    $.fn.tableCarousel = function (option, args) {
        var options = typeof option == "object" && option;

        return this.each(function () {
            var $this = $(this);
            var $tableCarousel = $this.data("tableCarousel");

            if (!$tableCarousel) {
                $tableCarousel = new TableCarousel($this, options);
                $this.data("tableCarousel", $tableCarousel);
            }

            if (typeof option == 'string') {
                $tableCarousel[option](args);
            } else {
                $tableCarousel.init();
            }
        });
    };

    $.fn.tableCarousel.defaults = {
        columns: 5
    };


}(jQuery));

